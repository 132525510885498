import { VoucherPageFragment } from '@/__generated__/graphql';
import { ComponentProps } from '@components/ComponentProps';
import Hero, { CreateHeroProps, VoucherHeroProps } from '@components/Hero';
import PageMeta from '@components/PageMeta';
import PreviewEditLink from '@components/PreviewEditLink';
import SocialShareBlock from '@components/SocialShareBlock';
import Slices from '@components/slices';
import { GlobalPageProps } from '@lib/utils';
import { GridContainer } from '@theme/lib';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import { JsonLd } from 'react-schemaorg';
import { Article } from 'schema-dts';

export type VoucherPageProps = ComponentProps<VoucherPageFragment>;

export default function VoucherPage({
  id,
  previewEditUrl,
  latestVersion,
  versionStatus,
  published,
  path,
  metatags,
  breadcrumbs,
  title,
  content,
  lastRevised,
  created,
  hero,
  socialLinks,
  pageUrl,
  siteName,
  homepageUrl,
}: VoucherPageProps & GlobalPageProps): ReactElement {
  const heroProps = hero ? (CreateHeroProps(hero) as VoucherHeroProps) : null;

  return (
    <>
      <PageMeta metatags={metatags} breadcrumbs={breadcrumbs} path={path} />
      <JsonLd<Article>
        item={{
          '@context': 'https://schema.org',
          '@type': 'Article',
          headline: heroProps?.title || title,
          description: metatags?.metaDescription || '',
          image: metatags?.metaImage?.md,
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': pageUrl,
          },
          author: {
            '@type': 'Organization',
            name: siteName,
            url: homepageUrl,
          },
          publisher: {
            '@type': 'Organization',
            name: siteName,
            url: homepageUrl,
          },
          dateModified: lastRevised
            ? dayjs.unix(parseInt(lastRevised)).format('YYYY-MM-DD')
            : undefined,
          datePublished: created ? dayjs.unix(parseInt(created)).format('YYYY-MM-DD') : undefined,
        }}
      />
      {previewEditUrl && (
        <PreviewEditLink
          url={previewEditUrl}
          latestVersion={latestVersion}
          versionStatus={versionStatus}
          published={published}
        />
      )}
      {heroProps && <Hero {...heroProps} />}
      <main>
        {content && <Slices {...content} />}
        {(socialLinks?.linkedin || socialLinks?.facebook || socialLinks?.twitter) && (
          <GridContainer>
            <SocialShareBlock pageUrl={pageUrl} {...socialLinks} />
          </GridContainer>
        )}
      </main>
    </>
  );
}
