import { LandingPageFragment } from "@/__generated__/graphql";
import { ComponentProps } from "@components/ComponentProps";
import GeneralListing from "@components/GeneralListing";
import Hero, { CreateHeroProps } from "@components/Hero";
import PageMeta from "@components/PageMeta";
import PreviewEditLink from "@components/PreviewEditLink";
import Slices from "@components/slices";
import SocialShareBlock from "@components/SocialShareBlock";
import { GlobalPageProps } from "@lib/utils";
import { GridContainer } from "@theme/lib";
import { ReactElement } from "react";

export type LandingPageProps = ComponentProps<
  LandingPageFragment
>;

export default function LandingPage({ id, previewEditUrl, latestVersion, versionStatus, published, path, metatags, breadcrumbs, title, content, hero, socialLinks, cardsListing, pageUrl }: LandingPageProps & GlobalPageProps): ReactElement {
  const heroProps = hero ? CreateHeroProps(hero) : null;
  return (
    <>
      <PageMeta
        metatags={metatags}
        breadcrumbs={breadcrumbs}
        path={path}
      />
      {previewEditUrl &&
        <PreviewEditLink url={previewEditUrl} latestVersion={latestVersion} versionStatus={versionStatus} published={published} />
      }
      {heroProps && <Hero {...heroProps} />}
      <main>
        {content && <Slices {...content} />}
        {cardsListing && (
          <GridContainer><GeneralListing cards={cardsListing} id={id} __typename={"GeneralListingParagraph"} showDescription={false} showEyebrow={false} /></GridContainer>
        )}
        {(socialLinks?.linkedin || socialLinks?.facebook || socialLinks?.twitter) && (
          <GridContainer><SocialShareBlock pageUrl={pageUrl} {...socialLinks} /></GridContainer>
        )}
      </main>
    </>
  );
}
