import { CoursePageFragment } from "@/__generated__/graphql";
import { ComponentProps } from "@components/ComponentProps";
import Hero, { CreateHeroProps } from "@components/Hero";
import InformationSection from "@components/InformationSection";
import PageMeta from "@components/PageMeta";
import PreviewEditLink from "@components/PreviewEditLink";
import Slices from "@components/slices";
import { GlobalPageProps } from "@lib/utils";
import { GridContainer, GridWrapper } from "@theme/lib";
import { ReactElement } from "react";
import { JsonLd } from "react-schemaorg";
import { Course } from "schema-dts";

export type CoursePageProps = ComponentProps<
  CoursePageFragment
>;

export default function CoursePage({ id, previewEditUrl, latestVersion, versionStatus, published, path, metatags, breadcrumbs, title, content, hero, informationSection }: CoursePageProps & GlobalPageProps): ReactElement {
  const heroProps = hero ? CreateHeroProps(hero) : null;
  return (
    <>
      <PageMeta
        metatags={metatags}
        breadcrumbs={breadcrumbs}
        path={path}
      />
      <JsonLd<Course>
        item={{
          "@context": "https://schema.org",
          "@type": "Course",
          "name": heroProps?.title || title,
          "description": metatags?.metaDescription || '',
        }}
      />
      {previewEditUrl &&
        <PreviewEditLink url={previewEditUrl} latestVersion={latestVersion} versionStatus={versionStatus} published={published} />
      }
      {heroProps && <Hero {...heroProps} />}
      <main>
        {informationSection &&
          <GridContainer>
            <GridWrapper alignment={"full-width"}>
              <InformationSection {...informationSection} />
            </GridWrapper>
          </GridContainer>}
        {content && <Slices {...content} />}
      </main>
    </>
  );
}
