import { PlacePageFragment } from "@/__generated__/graphql";
import { ComponentProps } from "@components/ComponentProps";
import Hero, { CreateHeroProps } from "@components/Hero";
import InformationSection from "@components/InformationSection";
import PageMeta from "@components/PageMeta";
import PreviewEditLink from "@components/PreviewEditLink";
import Slices from "@components/slices";
import { GlobalPageProps } from "@lib/utils";
import { GridContainer, GridWrapper } from "@theme/lib";
import { ReactElement } from "react";

export type PlacePageProps = ComponentProps<
  PlacePageFragment
>;

export default function PlacePage({ id, previewEditUrl, latestVersion, versionStatus, published, path, metatags, breadcrumbs, title, content, hero, informationSection }: PlacePageProps & GlobalPageProps): ReactElement {
  const heroProps = hero ? CreateHeroProps(hero) : null;
  return (
    <>
      <PageMeta
        metatags={metatags}
        breadcrumbs={breadcrumbs}
        path={path}
      />
      {previewEditUrl &&
        <PreviewEditLink url={previewEditUrl} latestVersion={latestVersion} versionStatus={versionStatus} published={published} />
      }
      {heroProps && <Hero {...heroProps} />}
      <main>
        {informationSection &&
          <GridContainer>
            <GridWrapper alignment={"full-width"}>
              <InformationSection {...informationSection} />
            </GridWrapper>
          </GridContainer>}
        {content && <Slices {...content} />}
      </main>
    </>
  );
}
