import { getLanguage } from '@/lib/config';
import { useMobile } from '@/lib/hooks/useMobile';
import { BorderRadius, breakpoints, colors, eyebrow, h2, imageLoaderResponsive, mediaQueries } from '@/theme/lib';
import Image from "next/legacy/image";
import { useState } from 'react';
import styled from 'styled-components';

const StyledGenericCard = styled.div`
  @keyframes backgroundhoverreverse {
    from {
      transform: rotate(-2deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  @keyframes arrowhoverreverse {
    from {
      transform: translateX(6px);
    }
    to {
      transform: translateX(0px);
    }
  }
  @keyframes backgroundhover {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-2deg);
    }
  }
  @keyframes arrowhover {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(6px);
    }
  }
  &.active {
    cursor: pointer;
    ${mediaQueries.md} {
      .generic-card__img-wrapper {
        &:after {
          animation: backgroundhover 0.25s ease-in forwards;
        }
      }
      .generic-card__text a:before {
        animation: arrowhover 0.25s ease-in forwards;
      }
    }
  }
  &.in-active {
    ${mediaQueries.md} {
      .generic-card__img-wrapper {
        &:after {
          animation: backgroundhoverreverse 0.25s ease-in forwards;
        }
      }
      .generic-card__text a:before {
        animation: arrowhoverreverse 0.25s ease-in forwards;
      }
    }
  }
  max-width: 256px; // Todo: this should really be overridden by wrapper.
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  ${mediaQueries.smHero} {
    max-width: 320px; // Todo: this should really be overridden by wrapper.
  }
  ${mediaQueries.md} {
    max-width: 590px;
    /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  }
  .generic-card__img-wrapper {
    height: auto;
    overflow: hidden;
    aspect-ratio: 590/355;
     > span {
      height: 100% !important;
      width: 100% !important;
     }
    ${mediaQueries.md} {
      position: relative;
      overflow: visible;
      &:after {
        content: '';
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        ${props => BorderRadius(props.theme.config.cards.generic.imageBorderRadius.desktop)}
        background: transparent;
        z-index: 99;
      }
    }
    ${mediaQueries.lg} {
      &:after {
        width: calc(100% - 1px);
      }
    }
    img {
      position: relative;
      z-index: 99;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 900;
      ${props => BorderRadius(props.theme.config.cards.generic.imageBorderRadius.mobile)}
      ${mediaQueries.md} {
        ${props => BorderRadius(props.theme.config.cards.generic.imageBorderRadius.desktop)}
      }
    }
  }
  .generic-card__text {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    > * + * {
      margin-top: 4px;
    }
    > span {
      ${eyebrow}
      text-transform: uppercase;
      line-height: 1.18rem;
      ${mediaQueries.lg} {
        font-size: 1.125rem;
        line-height: 1.375rem;
      }
    }
    a {
      font-family: ${(props) => props.theme.fonts.body};
      font-weight: ${props => props.theme.config.linkWeight};
      font-size: 1.25rem;
      line-height: 1.5rem;
      text-decoration: none;
      display: flex;
      gap: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &:after {
        content: '';
        display: block;
        background: none;
        position: absolute;
        width: unset;
        height: unset;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: 0;
      }
      ${mediaQueries.lg} {
        font-size: 2.25rem;
        line-height: 2.5rem;
      }
      &:before {
        content: '';
        display: block;
        height: 1.5rem;
        width: 3rem;
        background-image: url( ${props => `/GenericCard/arrow-mobile--${props.theme.key}.svg`});
        background-repeat: no-repeat;
        background-size: contain;
        order: 1;
        background-position: center;
        flex: 0 0 3rem;

        ${mediaQueries.lg} {
          height: 2rem;
          width: 2.5rem;
          position: relative;
          top: 0.3rem;
          background-image: url( ${props => `/GenericCard/arrow-desk--${props.theme.key}.svg`});
          flex-basis: 2.5rem
        }
      }
    }
    p {
      line-height: 1.375rem;
      ${mediaQueries.lg} {
        font-size: 1.125rem;
        line-height: 1.625rem;
      }
    }
  }
  .generic-card__label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 900;
    padding: 10px;
    margin: 8px;
    border-radius: 10px;
    background: #FFFFFF;
    border: 2px solid ${(props) => props.theme.colors.cards.generic.label};
    font-family: ${(props) => props.theme.fonts.body};
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color:  ${(props) => colors.zooFont};
    ${mediaQueries.lg} {
     font-size: 18px;
     padding: 14x;
     margin: 16px 0px 0px 18px;
    }
  }
  .generic-card__date {
    font-weight: bold;
    &::after {
      content: ' - ';
      font-weight: normal;
    }
  }
`;

// Todo: later on when implementing concrete card, we can probably use theme variable for each site.
export const StyledZSLCard = styled(StyledGenericCard)`
  .generic-card {
    &__img-wrapper {
      &:after {
        background: ${(props) => props.theme.colors.cards.generic.imageBackground};
      }
    }
    &__text {
      > span {
       color: ${(props) => props.theme.colors.cards.generic.eyebrow};
      }
      h3 {
        ${h2}
      }
      a {
        color: ${(props) => props.theme.colors.cards.generic.link};
      }
      p {
        color: ${(props) => props.theme.colors.cards.generic.paragraph};
      }
    }
  }
`;

export interface GenericCardProps {
  path: string;
  target?: string|null;
  title: string;
  eyebrow?: string;
  description: string|null;
  hideEyebrow?: boolean;
  hideDescription?: boolean;
  h3?: boolean;
  image: {
    alt: string|null;
    url: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  className?: string;
  label?: string;
  created?: string|null;
}

export default function GenericCard(data: GenericCardProps) {
  const [mouseState, setMouseState] = useState<string | null>(null);
  const isMobile = useMobile();
  const language = getLanguage();

  return (
    <StyledZSLCard
      className={`generic-card ${mouseState ? mouseState : ''} ${data.className}`}
      onMouseOver={() => setMouseState('active')}
      onMouseLeave={() => setMouseState('in-active')}
      onClick={() => window && window.open(data.path, data.target ? data.target : '_top')}
    >
      <div className="generic-card__img-wrapper">
        <Image
          src={data.image.url}
          alt={data.image.alt || ''}
          layout="intrinsic"
          objectFit="cover"
          sizes={`(min-width: ${breakpoints.sm}px) 345px,
                  (min-width: ${breakpoints.md}px) 590px,
                  345px`}
          width={isMobile ? 345 : 590}
          height={isMobile ? 256 : 348}
          loader={imageLoaderResponsive(isMobile ? 345 : 590, isMobile ? 256 : 348)}
        />
        {data.label && <div className="generic-card__label">{data.label}</div>}
      </div>
      <div className="generic-card__text">
        {data.eyebrow && !data.hideEyebrow && <span dangerouslySetInnerHTML={{__html: data.eyebrow}} lang={language !== 'en' ? language : undefined} />}
        {data.h3 ? (
          <h3>
            <a href={data.path} target={data.target || undefined} onClick={(e) => e.stopPropagation()} lang={language !== 'en' ? language : undefined}>
              {data.title}
            </a>
          </h3>
        ) : (
          <h2>
            <a href={data.path} target={data.target || undefined} onClick={(e) => e.stopPropagation()} lang={language !== 'en' ? language : undefined}>
              {data.title}
            </a>
          </h2>
        )}
        {(!data.hideDescription && data?.description) &&
          <p lang={language !== 'en' ? language : undefined}>{data.created && <span className="generic-card__date">{data.created}</span>}{data.description}</p>}
      </div>
    </StyledZSLCard>
  );
}
