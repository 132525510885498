import { PersonPageFragment } from "@/__generated__/graphql";
import { ComponentProps } from "@components/ComponentProps";
import Hero, { CreateHeroProps } from "@components/Hero";
import InformationSection from "@components/InformationSection";
import PageMeta from "@components/PageMeta";
import PreviewEditLink from "@components/PreviewEditLink";
import Slices from "@components/slices";
import { GlobalPageProps } from "@lib/utils";
import { GridContainer, GridWrapper } from "@theme/lib";
import { ReactElement } from "react";
import { JsonLd } from "react-schemaorg";
import { Person } from "schema-dts";

export type PersonPageProps = ComponentProps<
  PersonPageFragment
>;

export default function PersonPage({ id, previewEditUrl, latestVersion, versionStatus, published, path, metatags, breadcrumbs, title, content, hero, informationSection, personJobTitle, googleScholar, linkedin, instagram, twitter }: PersonPageProps & GlobalPageProps): ReactElement {
  const heroProps = hero ? CreateHeroProps(hero) : null;
  let links = [];
  if (googleScholar) links.push(googleScholar);
  if (linkedin) links.push(linkedin);
  if (twitter) links.push(twitter);
  if (instagram) links.push(instagram);

  return (
    <>
      <PageMeta
        metatags={metatags}
        breadcrumbs={breadcrumbs}
        path={path}
      />
      <JsonLd<Person>
        item={{
          "@context": "https://schema.org",
          "@type": "Person",
          "name": heroProps?.title || title,
          "description": metatags?.metaDescription || '',
          "image": metatags?.metaImage?.md,
          "jobTitle": personJobTitle || undefined,
          "sameAs": links && links.length ? links : undefined,
        }}
      />
      {previewEditUrl &&
        <PreviewEditLink url={previewEditUrl} latestVersion={latestVersion} versionStatus={versionStatus} published={published} />
      }
      {heroProps && <Hero {...heroProps} />}
      <main>
        {informationSection &&
          <GridContainer>
            <GridWrapper alignment={"full-width"}>
              <InformationSection {...informationSection} />
            </GridWrapper>
          </GridContainer>}
        {content && <Slices {...content} />}
      </main>
    </>
  );
}
