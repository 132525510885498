import { InformationPageFragment } from "@/__generated__/graphql";
import { ComponentProps } from "@components/ComponentProps";
import Hero, { CreateHeroProps } from "@components/Hero";
import PageMeta from "@components/PageMeta";
import PreviewEditLink from "@components/PreviewEditLink";
import Slices from "@components/slices";
import SocialShareBlock from "@components/SocialShareBlock";
import { GlobalPageProps } from "@lib/utils";
import { GridContainer, GridWrapper, SmallText } from "@theme/lib";
import dayjs from "dayjs";
import { ReactElement } from "react";

export type InformationPageProps = ComponentProps<
  InformationPageFragment
>;

export default function InformationPage({ id, previewEditUrl, latestVersion, versionStatus, published, path, metatags, breadcrumbs, title, content, displayLastModified, lastRevised, hero, socialLinks, pageUrl }: InformationPageProps & GlobalPageProps): ReactElement {
  const heroProps = hero ? CreateHeroProps(hero) : null;
  return (
    <>
      <PageMeta
        metatags={metatags}
        breadcrumbs={breadcrumbs}
        path={path}
      />
      {previewEditUrl &&
        <PreviewEditLink url={previewEditUrl} latestVersion={latestVersion} versionStatus={versionStatus} published={published} />
      }
      {heroProps && <Hero {...heroProps} />}
      <main>
        {displayLastModified && lastRevised && (
          <GridContainer className={"LastModified"}>
            <GridWrapper alignment={'left'}>
              <SmallText>Last modified: {dayjs.unix(parseInt(lastRevised)).format('D MMMM YYYY')}</SmallText>
            </GridWrapper>
          </GridContainer>
        )}
        {content && <Slices {...content} />}
        {(socialLinks?.linkedin || socialLinks?.facebook || socialLinks?.twitter) && (
          <GridContainer><SocialShareBlock pageUrl={pageUrl} {...socialLinks} /></GridContainer>
        )}
      </main>
    </>
  );
}
