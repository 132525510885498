import GenericCard, { GenericCardProps } from "@components/GenericCard";
import WYSIWYG from "@components/WYSIWYG";

import { GeneralCardFragment, GeneralListingParagraph, WYSIWYGFragment } from "@/__generated__/graphql";
import { getLanguage } from '@/lib/config';
import { StyledGeneralListing } from './GeneralListing.styles';

export const listingToCard = (data: GeneralCardFragment):GenericCardProps => {
  return {
    path: data.path,
    target: data.target,
    title: data.title || '',
    description: data.teaserDescription ?? '',
    eyebrow: data.teaserEyebrow ?? undefined,
    image: {
      alt: data.teaserImage.altText,
      url: data.teaserImage.url,
      sm: data.teaserImage.sm,
      md: data.teaserImage.md,
      lg: data.teaserImage.lg,
      xl: data.teaserImage.xl,
    }
  }
}

export default function GeneralListing({
  callToAction,
  cards,
  description,
  id,
  showDescription = true,
  showEyebrow = true,
  title
}: GeneralListingParagraph) {
  if (!cards?.length) {
    return <></>;
  }
  const language = getLanguage();
  return (
    <StyledGeneralListing alignment={'listing'}>
      <div className="general-listing__intro">
        {(title || description) && (
          <div className="general-listing__intro-text">
            {title && (
              <h2 className="general-listing__title" lang={language !== 'en' ? language : undefined}>{title}</h2>
            )}
            {description && (
              <div className="general-listing__description">
                <WYSIWYG id="general-listing__wysiwig" text={description as WYSIWYGFragment['text']} alignment="left" />
              </div>
            )}
          </div>
        )}
        {callToAction && (
          <a href={callToAction.url} className="btn btn-primary btn-primary--desktop" lang={language !== 'en' ? language : undefined}>{callToAction.title}</a>
        )}
      </div>
      <ul className="general-listing__cards-list">
        {cards.map((card, key) => {
          const cardData = listingToCard(card);
          return <GenericCard
            key={`general-listing__card-${key}`}
            hideEyebrow={showEyebrow === false}
            hideDescription={showDescription === false}
            {...cardData}
          />
        })
        }
      </ul>
      {callToAction && (
        <a href={callToAction.url} className="btn btn-primary btn-primary--mobile" lang={language !== 'en' ? language : undefined}>{callToAction.title}</a>
      )}
    </StyledGeneralListing>
  );
}
