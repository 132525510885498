import { breakpoints, imageLoaderResponsive, mediaQueries } from '@/theme/lib';
import Image from 'next/legacy/image';
import styled from 'styled-components';

export type StyledPeopleCardProps = {
  small: boolean;
};

const StyledPeopleCard = styled.div<StyledPeopleCardProps>`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 16px;
  max-width: 324px;
  ${(props) =>
    props.small
      ? ``
      : `
    ${mediaQueries.md} {
      max-width: 390px;
    }
    ${mediaQueries.lg} {
      max-width: 532px;
    }
    ${mediaQueries.xl} {
      max-width: 592px;
      gap: 32px;
    }`}
  .people-card__img-wrapper {
    height: 120px;
    width: 120px;
    border-radius: ${(props) => props.theme.config.componentRadius};
    flex: 0 0 auto;
    overflow: hidden;
    ${(props) =>
      props.small
        ? ``
        : `
      ${mediaQueries.md} {
        height: 179px;
        width: 179px;
      }
      ${mediaQueries.lg} {
        height: 250px;
        width: 250px;
      }
      ${mediaQueries.xl} {
        height: 280px;
        width: 280px;
      }`}
    img {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .people-card__text {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 4px;
    a {
      font-family: ${(props) => props.theme.fonts.body};
      font-weight: ${props => props.theme.config.linkWeight};
      font-size: 1.25rem;
      line-height: 1.5rem;
      text-decoration: none;
      display: flex;
      width: 100%;
      justify-content: space-between;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
      ${(props) =>
        props.small
          ? ``
          : `
        ${mediaQueries.lg} {
          font-size: 1.5rem;
          line-height: 1.8rem;
        }`}
      svg {
        float: right;
        margin-left: 1rem;
        flex: 0 0 auto;
        height: 1.5rem;
        width: 1.8rem;
        ${(props) =>
          props.small
            ? ``
            : `
          ${mediaQueries.lg} {
            margin-left: 1.5rem;
            height: 1.8rem;
          }`}
      }
    }
    p {
      color: ${(props) => props.theme.colors.text};
      font-size: 1rem;
      line-height: 1.4rem;

      ${(props) =>
        props.small
          ? ``
          : `
        ${mediaQueries.lg} {
          font-size: 1.125rem;
          line-height: 1.625rem;
        }`}
    }
  }
`;

export interface PeopleCardProps {
  url: string;
  name: string;
  small?: boolean;
  jobTitle: string;
  image: {
    altText: string | null;
    url: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
}

export default function PeopleCard(data: PeopleCardProps) {
  return (
    <StyledPeopleCard small={data.small || false} className={`people-card`}>
      <div className="people-card__img-wrapper">
        {data.image.url ? <Image
          src={data.image.url}
          alt={data.image.altText || ''}
          layout="intrinsic"
          objectFit="cover"
          sizes={`(min-width: ${breakpoints.sm}px) ${data.small ? 140 : 280}px,
                  (min-width: ${breakpoints.md}px) ${data.small ? 140 : 280}px,
                  140px`}
          width={data.small ? 140 : 280}
          height={data.small ? 140 : 280}
          loader={imageLoaderResponsive(data.small ? 140 : 280, data.small ? 140 : 280)}
        />
        :
          <Image
            src='/PeopleCard/person-placeholder.svg'
            alt=''
            layout="intrinsic"
            objectFit="cover"
            width={data.small ? 140 : 280}
            height={data.small ? 140 : 280}
          />}
      </div>
      <div className="people-card__text">
        <h2>
          {data.url ? <a href={data.url}>
              {data.name}
              <svg
                width="31"
                height="16"
                viewBox="0 0 31 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 7H0L0 9H1L1 7ZM30.5943 8.70711C30.9848 8.31658 30.9848 7.68342 30.5943 7.29289L24.2303 0.928932C23.8398 0.538408 23.2066 0.538408 22.8161 0.928932C22.4256 1.31946 22.4256 1.95262 22.8161 2.34315L28.4729 8L22.8161 13.6569C22.4256 14.0474 22.4256 14.6805 22.8161 15.0711C23.2066 15.4616 23.8398 15.4616 24.2303 15.0711L30.5943 8.70711ZM1 9L29.8872 9V7L1 7L1 9Z"
                  fill="#C7F700"
                />
              </svg>
            </a>
            :
            <a>
              {data.name}
            </a>
          }
        </h2>
        <p>{data.jobTitle}</p>
      </div>
    </StyledPeopleCard>
  );
}
