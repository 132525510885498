import { NewsPageFragment } from "@/__generated__/graphql";
import { ComponentProps } from "@components/ComponentProps";
import Hero, { CreateHeroProps } from "@components/Hero";
import InformationSection from "@components/InformationSection";
import PageMeta from "@components/PageMeta";
import PreviewEditLink from "@components/PreviewEditLink";
import Slices from "@components/slices";
import SocialShareBlock from "@components/SocialShareBlock";
import { GlobalPageProps } from "@lib/utils";
import { Date, GridContainer, GridWrapper } from "@theme/lib";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { JsonLd } from "react-schemaorg";
import { NewsArticle } from "schema-dts";

export type NewsPageProps = ComponentProps<
  NewsPageFragment
>;

export default function NewsPage({ id, previewEditUrl, latestVersion, versionStatus, published, path, metatags, breadcrumbs, title, content, lastRevised, hero, informationSection, socialLinks, pageUrl, created, author, homepageUrl, siteName }: NewsPageProps & GlobalPageProps): ReactElement {
  const heroProps = hero ? CreateHeroProps(hero) : null;
  return (
    <>
      <PageMeta
        metatags={metatags}
        breadcrumbs={breadcrumbs}
        path={path}
      />
      <JsonLd<NewsArticle>
        item={{
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "headline": heroProps?.title || title,
          "description": metatags?.metaDescription || '',
          "image": metatags?.metaImage?.md,
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": pageUrl,
          },
          "author": {
            "@type": author?.title ? "Person" : "Organization",
            "name": author?.title || siteName,
            "url": author?.url || homepageUrl,
          },
          "publisher": {
            "@type": "Organization",
            "name": siteName,
            "url": homepageUrl,
          },
          "dateModified": lastRevised ? dayjs.unix(parseInt(lastRevised)).format('YYYY-MM-DD') : undefined,
          "datePublished": created ? dayjs.unix(parseInt(created)).format('YYYY-MM-DD') : undefined,
        }}
      />
      {previewEditUrl &&
        <PreviewEditLink url={previewEditUrl} latestVersion={latestVersion} versionStatus={versionStatus} published={published} />
      }
      {heroProps && <Hero {...heroProps} />}
      <main>
        {informationSection &&
          <GridContainer>
            <GridWrapper alignment={"left"}>
              <InformationSection {...informationSection} />
            </GridWrapper>
          </GridContainer>}
        {created && (
          <GridContainer className={"LastModified"}>
            <GridWrapper alignment={'left'}>
              <Date>{dayjs.unix(parseInt(created)).format('D MMMM YYYY')}</Date>
            </GridWrapper>
          </GridContainer>
        )}
        {content && <Slices {...content} />}
        {(socialLinks?.linkedin || socialLinks?.facebook || socialLinks?.twitter) && (
          <GridContainer><SocialShareBlock pageUrl={pageUrl} {...socialLinks} /></GridContainer>
        )}
      </main>
    </>
  );
}
